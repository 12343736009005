import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { delayHeavyTask } from 'client/site-modules/shared/utils/inp-utils';
import { DrawerV2 } from 'site-modules/shared/components/drawer/drawer-v2';

import { AppraisalFindVinDrawerBackButton } from './appraisal-find-vin-drawer-back-button';
import { AppraisalFindVinDrawerContentAsync } from './appraisal-find-vin-drawer-content-async';
import { DRAWER_TRIGGER_CTA_TEXT } from './constants';

import './find-vin-modal.scss';

export const CLOSE_VIN_DRAWER_EVENT = 'CLOSE_VIN_DRAWER';

export class AppraisalFindVinDrawer extends Component {
  static propTypes = {
    creativeId: PropTypes.string.isRequired,
    changeStep: PropTypes.func,
    licensePlateAndVinForm: PropTypes.node,
    className: PropTypes.string,
    isLicensePlate: PropTypes.bool,
    isAddressToVinEnabled: PropTypes.bool,
    isOptimizedTraffic: PropTypes.bool,
    isMobile: PropTypes.bool,
    isLandingPage: PropTypes.bool,
    updateVinAndContinue: PropTypes.func,
    trackVinEntry: PropTypes.func,
    updateStepsState: PropTypes.func,
    onPartnerOfferVinEligibilityUpdate: PropTypes.func,
    activeTabId: PropTypes.string,
    isBackgroundStyleTabEnabled: PropTypes.bool,
    clearSquishVinEligibility: PropTypes.func,
    clearVin: PropTypes.func,
    displayAsLink: PropTypes.bool,
    vehicle: PropTypes.shape({
      make: PropTypes.shape({
        name: PropTypes.string,
      }),
      model: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    openCtaAsButtonClassName: PropTypes.string,
    iconClassName: PropTypes.string,
  };

  static defaultProps = {
    licensePlateAndVinForm: null,
    className: '',
    isLicensePlate: false,
    isAddressToVinEnabled: false,
    isOptimizedTraffic: false,
    isMobile: false,
    isLandingPage: false,
    vehicle: {},
    activeTabId: '',
    updateVinAndContinue: noop,
    trackVinEntry: noop,
    onPartnerOfferVinEligibilityUpdate: noop,
    updateStepsState: noop,
    changeStep: noop,
    clearSquishVinEligibility: noop,
    clearVin: noop,
    isBackgroundStyleTabEnabled: false,
    displayAsLink: false,
    openCtaAsButtonClassName: 'bg-primary-opacity-2',
    iconClassName: 'text-primary-darker',
  };

  state = {
    isOpen: false,
    showVinLpForm: false,
    wasDrawerOpenedBefore: false,
  };

  componentDidMount() {
    EventToolbox.on(CLOSE_VIN_DRAWER_EVENT, this.handleDrawerClose);
  }

  componentWillUnmount() {
    EventToolbox.off(CLOSE_VIN_DRAWER_EVENT, this.handleDrawerClose);
  }

  handleDrawerClose = () => {
    this.setState(() => ({ isOpen: false }));
  };

  trackDrawerToggle = () => {
    const { creativeId, activeTabId, isLandingPage } = this.props;
    const { isOpen } = this.state;
    const trackingValue = isLandingPage ? activeTabId : 'open';

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        subaction_name: isOpen ? TrackingConstant.FIND_VIN : TrackingConstant.CLOSE_POPUP,
        creative_id: creativeId,
        value: isOpen ? trackingValue : 'close',
      },
    });
  };

  cbDrawerToggle = () => {
    delayHeavyTask(() => {
      this.props.clearVin();
      this.props.clearSquishVinEligibility();
      this.trackDrawerToggle();
    });
  };

  toggleDrawer = () =>
    this.setState(
      prevState => ({ isOpen: !prevState.isOpen, showVinLpForm: false, wasDrawerOpenedBefore: true }),
      this.cbDrawerToggle
    );

  toggleDrawerWithoutTracking = () =>
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      showVinLpForm: false,
      wasDrawerOpenedBefore: true,
    }));

  toggleVinLpForm = () => {
    this.setState(prevState => ({
      showVinLpForm: !prevState.showVinLpForm,
    }));
  };

  renderButton = ctaText => {
    const {
      isBackgroundStyleTabEnabled,
      className,
      iconClassName,
      isLandingPage,
      displayAsLink,
      openCtaAsButtonClassName,
    } = this.props;

    const shouldRenderAsButton = isBackgroundStyleTabEnabled && !displayAsLink;

    return shouldRenderAsButton ? (
      <Button
        color="custom"
        className={classNames('px-1 py-0_25 rounded-pill size-14 mt-0_5', openCtaAsButtonClassName)}
        onClick={this.toggleDrawer}
      >
        <i className={classNames('icon icon-search', iconClassName)} aria-hidden />
        <span className="font-weight-normal text-gray-darker ml-0_25">{ctaText}</span>
      </Button>
    ) : (
      <Button
        color="link"
        className={classNames(
          'p-0 border-0 text-primary-darker text-transform-none size-16 find-vin-modal-open-btn',
          className
        )}
        onClick={this.toggleDrawer}
      >
        <span className="font-weight-normal">
          {ctaText} {isLandingPage && !displayAsLink && <i className="icon-arrow-right4 size-12" aria-hidden />}
        </span>
      </Button>
    );
  };

  render() {
    const { isOpen, wasDrawerOpenedBefore, showVinLpForm } = this.state;
    const { isLicensePlate, isLandingPage } = this.props;
    const detailType = isLicensePlate ? 'licensePlate' : 'vin';
    const ctaText = isLandingPage ? DRAWER_TRIGGER_CTA_TEXT.address : DRAWER_TRIGGER_CTA_TEXT[detailType];

    return (
      <Fragment>
        <DrawerV2 isOpen={isOpen} toggle={this.toggleDrawer} side="right" size="xsmall">
          {(isOpen || wasDrawerOpenedBefore) && (
            <div className="p-1_5 p-md-2">
              <AppraisalFindVinDrawerContentAsync
                lazyLoadingPlaceholderBackButtonSlot={
                  <AppraisalFindVinDrawerBackButton toggleDrawer={this.toggleDrawer} />
                }
                {...this.props}
                toggleDrawer={this.toggleDrawer}
                toggleDrawerWithoutTracking={this.toggleDrawerWithoutTracking}
                toggleVinLpForm={this.toggleVinLpForm}
                isDrawerOpen={isOpen}
                showVinLpForm={showVinLpForm}
              />
            </div>
          )}
        </DrawerV2>
        {this.renderButton(ctaText)}
      </Fragment>
    );
  }
}
